<style lang="less" scoped>
.task-list,
.task-list-selected {
  border-radius: 5px;
  padding: 8px 8px;
  cursor: pointer;
  &:hover {
    // box-shadow: 0 0 3px #fff;
    // -webkit-box-shadow: 0 0 3px #fff;
    // -moz-box-shadow: 0 0 3px #fff;
    border-color: #568dfa;
  }
}
.task-list {
  border: 1px solid #cccc;
}
.task-list-selected {
  border: 1px solid #2058c9;
}
</style>
<template>
  <div>
    <Row
      v-if="list&&list.length"
      :gutter="8"
    >
      <i-col span="12">
        <div
          :class="item.reportTroubleId===showReportTroubleId?'task-list-selected m-b-10':'task-list m-b-10'"
          v-for="(item,index) in list"
          :key="'task_'+index"
          @click="handleCheckedTask(item)"
        >
          <div class="m-b-5">
            <span>上报现象：</span>{{item.category1Name}} - {{item.category2Name}}
          </div>
          <div class="m-b-5">
            <span>问题描述：</span>{{item.remark||'暂无'}}
          </div>
          <div class="m-b-5">
            <span>当前状态：</span>{{item.statusName}}
          </div>
          <Row
            :gutter="8"
            class="m-b-5"
          >
            <i-col span="14"><span>上报时间：</span>{{item.reportTime}}</i-col>
            <i-col
              span="10"
              class="text-right"
            >
              <Button
                v-if="item.status===0"
                type="error"
                size="small"
                class="m-r-5"
                @click="handleRefuse(item)"
              >拒绝处理</Button>
              <Button
                v-if="item.status===0"
                type="primary"
                size="small"
                @click.stop="handelInsertTask(item)"
              >加入任务</Button>
            </i-col>
          </Row>
        </div>
        <div class="paging_style">
          <Page
            size="small"
            :total="total"
            :page-size="query.pageSize"
            show-total
            show-elevator
            :current="query.pageNumber"
            @on-change="handleChangePage"
          ></Page>
        </div>
      </i-col>
      <i-col span="12">
        <div v-if="reportAttachList.length">
          <h4 class="m-b-10">上报问题画面</h4>
          <Carousel
            loop
            v-model="imgIndex"
            :radius-dot="true"
            style="width:480px"
          >
            <CarouselItem
              v-for="item in reportAttachList"
              :key="'rep_'+item.id"
            >
              <img
                :src="item.fileKey+'?x-oss-process=image/resize,w_480'"
                width="480"
              />
            </CarouselItem>
          </Carousel>
        </div>
      </i-col>
    </Row>
    <div
      v-else
      class="default-content"
    >
      <p style="font-size: x-large;">暂无数据</p>
    </div>
    <Modal
      v-if="reportTroubleId"
      v-model="insertTaskModal"
      width="1000"
      footer-hide
    >
      <p
        slot="header"
        class="text-center"
      >选择任务</p>
      <task-list
        ref="task"
        :entrance="2"
        @handleSetSuccess="handleSetSuccess"
      ></task-list>
    </Modal>
  </div>
</template>

<script>
import { getDeviceReportPage } from '@/api/msp/device'
import { closeTrouble } from '@/api/msp/trouble'
import TaskList from './TaskList'
export default {
  components: {
    TaskList
  },
  data () {
    return {
      query: {
        pageNumber: 1,
        pageSize: 6,
        deviceId: null
      },
      list: [],
      total: 0,
      imgIndex: 0,
      attachList: [], // 反馈照片信息
      reportAttachList: [], // 故障上报照片信息
      showReportTroubleId: null, // 当前显示的故障id
      insertTaskModal: false,
      reportTroubleId: null
    }
  },
  computed: {
    deviceId () {
      return this.$store.state.equipment.detailId
    }
  },
  created () {
    if (this.deviceId) {
      this.getReportData()
    }
  },
  methods: {
    getReportData () {
      this.query.deviceId = this.deviceId
      getDeviceReportPage(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
          this.reportAttachList = this.list.length ? this.list[0].reportAttachList : []
          this.showReportTroubleId = this.list.length ? this.list[0].reportTroubleId : null
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
          this.reportAttachList = []
          this.showReportTroubleId = null
        }
      })
    },
    handleChangePage (curPage) {
      this.query.pageNumber = curPage
      this.getReportData()
    },
    /** 拒绝处理 */
    handleRefuse (taskItem) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要拒绝处理该项？',
        onOk: () => {
          closeTrouble({ troubleIds: JSON.stringify([taskItem.reportTroubleId]) }).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.getReportData()
            }
          })
        }
      })
    },
    handleCheckedTask (taskItem) {
      this.imgIndex = 0
      this.attachList = taskItem.attachList
      this.reportAttachList = taskItem.reportAttachList
      this.showReportTroubleId = taskItem.reportTroubleId
    },
    /**
     * 上报故障加入任务
     */
    handelInsertTask (taskItem) {
      this.$store.commit('set_cur_device_rapair_info', taskItem)
      this.reportTroubleId = taskItem.reportTroubleId
      this.insertTaskModal = true
      this.$nextTick(() => {
        this.$refs.task.getTableData()
      })
    },
    handleSetSuccess () {
      this.insertTaskModal = false
      this.getReportData()
    }
  }
}
</script>
